import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "InstarVision Surveillance Center",
  "path": "/Software/Android/InstarVision/Wizard/Add_Camera/New_IP_Cam/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Um mit unserer Android App InstarVision auf Ihre IP-Kamera zuzugreifen, empfehlen wir Ihnen, zuerst die neueste Version aus dem App Store herunterzuladen. Verwenden Sie das Suchwort Instar, um unsere App im Geschäft zu finden.",
  "image": "./P_SearchThumb_InstarVision_Android.png",
  "social": "/images/Search/P_SearchThumb_InstarVision_Android.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Android-InstarVision_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "android-app-installation-wizard",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#android-app-installation-wizard",
        "aria-label": "android app installation wizard permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Android App Installation Wizard`}</h2>
    <h3 {...{
      "id": "add-a-new-p2p-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-a-new-p2p-camera",
        "aria-label": "add a new p2p camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add a New P2P Camera`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3ab8dca170acffd39d6c44c3a6d1e4c4/81315/Android_Wizard_New_IP_Cam_d01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB9UlEQVQ4y6XS3W+bMBAAcN6z5CkvmZKgMS0LJdz5A4zB2DiE9GNRGtpma6X9///HhNOgrlO3afvJD6czd7axvclkEkXReDweDAbvHN/3wzCMVqupHyyc4XA4Go2Gznw+Xy6XAOAHn7wwDIMgsNYyxnzfD4LA9/3ZbDr1P0zo1edl+DEIlFJVVWmtF4uFm51NZ/P3fOdFDgDEcRydrZxoBacYzrpkPxuDRx3i0DeQs1dpr5/As1P8Kv8y6HnPHRljnDPG2Vn/RZ85LdfHrhiRwEoV0lbalMoYU1WVMYYSpG4xmefGZetNZ71eW2uVUl0x5SkzN93Q18x8ESJL05QSBJ5DUnStsevCCBpdVlXFOaeEMkoogscTUdQ33bCNWTdaGyklQUAukHJkKeQNFA0UlyAMdacgiJAUkGqPZ3ne3Ip6n1dNLoVSqigUowS4jLkUQrTHx8PDt/b4dLg/7na7/X6fCXEht1G580SaXl9uTalklkkpc4d1GxVIk4TT2mprytoaVeSMd55/J6UeIsYA+LP+moClkbCRqEJhgSaI0FURgixFJjzyNkRMRdYen46P32/vv97dP7RtezgctNZhfnVh7v5QzDnf1HVdrzd1vak32+1WZBmjFLmEtPxd8Qm8EMfx6VAUsbsq8nd+fef98/xH/1X8AwFR0ohVPoabAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3ab8dca170acffd39d6c44c3a6d1e4c4/e4706/Android_Wizard_New_IP_Cam_d01.avif 230w", "/en/static/3ab8dca170acffd39d6c44c3a6d1e4c4/d1af7/Android_Wizard_New_IP_Cam_d01.avif 460w", "/en/static/3ab8dca170acffd39d6c44c3a6d1e4c4/7f308/Android_Wizard_New_IP_Cam_d01.avif 920w", "/en/static/3ab8dca170acffd39d6c44c3a6d1e4c4/e1c99/Android_Wizard_New_IP_Cam_d01.avif 1380w", "/en/static/3ab8dca170acffd39d6c44c3a6d1e4c4/6e1e4/Android_Wizard_New_IP_Cam_d01.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3ab8dca170acffd39d6c44c3a6d1e4c4/a0b58/Android_Wizard_New_IP_Cam_d01.webp 230w", "/en/static/3ab8dca170acffd39d6c44c3a6d1e4c4/bc10c/Android_Wizard_New_IP_Cam_d01.webp 460w", "/en/static/3ab8dca170acffd39d6c44c3a6d1e4c4/966d8/Android_Wizard_New_IP_Cam_d01.webp 920w", "/en/static/3ab8dca170acffd39d6c44c3a6d1e4c4/445df/Android_Wizard_New_IP_Cam_d01.webp 1380w", "/en/static/3ab8dca170acffd39d6c44c3a6d1e4c4/87a8c/Android_Wizard_New_IP_Cam_d01.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3ab8dca170acffd39d6c44c3a6d1e4c4/81c8e/Android_Wizard_New_IP_Cam_d01.png 230w", "/en/static/3ab8dca170acffd39d6c44c3a6d1e4c4/08a84/Android_Wizard_New_IP_Cam_d01.png 460w", "/en/static/3ab8dca170acffd39d6c44c3a6d1e4c4/c0255/Android_Wizard_New_IP_Cam_d01.png 920w", "/en/static/3ab8dca170acffd39d6c44c3a6d1e4c4/b1001/Android_Wizard_New_IP_Cam_d01.png 1380w", "/en/static/3ab8dca170acffd39d6c44c3a6d1e4c4/81315/Android_Wizard_New_IP_Cam_d01.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3ab8dca170acffd39d6c44c3a6d1e4c4/c0255/Android_Wizard_New_IP_Cam_d01.png",
              "alt": "Android App Installation Wizard",
              "title": "Android App Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`[01]`}</strong>{` Start by entering the `}<strong parentName="li">{`Cameras`}</strong>{` menu and select the `}<strong parentName="li">{`Add Camera`}</strong>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`[02]`}</strong>{` You can now continue with adding the the remote access via the `}<a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/Android/P2P/"
        }}>{`Point2Point (P2P)`}</a>{` or `}<a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/Android/DDNS/"
        }}>{`DDNS`}</a>{` service.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3c9a390cc8932f8d36bb3228d9dc6885/81315/Android_Wizard_New_IP_Cam_d02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC3klEQVQ4y33SXU/aUBjAcT4A+wASi5IYMVmgUB0vTdAFqCMZ3JjMRCTLtgtnAIdUCrS8FYqoQAUxZZWVnraIaLYs26fYdrNsX2ReueuF4jaTwX45F+fmnzx5ztHMzc0ZDAa7anZ2VqeamprS6XQQBBmNxvn5ea1We0+l1Wr1ev3CwoLZbJ6ZmdG4VX7/40DAv6oKBPxra2vr6098vkdGo9FgMKysrDxUWSwWCIL0ev309DQEQRqCSCSTRDqdymQzNE0zDNNsNgAAV1eXx8esw+5AURSGYbPKZDLdV5lMJgRBNEmCSKXTFEVls5lcPksX8pXKPsdxkgTabc7j8bhcLqvVavltdF9cXERRVLO3h6dSyUyGKhYL5TJzcFCp12s8z8uyBEAXwzCr1QrDsPWOvzFFkTRdqNWqHMcJgiCKoizL50MKACKGYcvLywiCjI8ZplSrV1unrU7njaLIvfNevz88g8GFKHa9Xq/T6bT8A0EQp9OpoenC4eEBy7IcxwEgyjJQFFlRpMGgD4Do9XpdLtfEOJ8fbohl6+12GwCgKFKvp/R68uDyQpLA6irmcDgmxqVSsV6vtVqtdrstil1ZlmRFUhR5MLgAQPT5fCiKTox5nv/2/evnL58kCfA8f3Z2BoCoxsOxPR6Pw+FAEORuCcMwgiDDhQmCcPPz5vr6BwAiy7LNZoPjuE6n0++fi6A7emeb7cHohf+43TZN02/fvf3w8X21Wi2XmUplv1arnpyciGJXEAQMw/43No7HKYrM53NpMk2SZC6XZZhio8Hy/GuO4zAMs9vtE+NYLEZRVLFYxHF8Z2cnFnsVj+8eHR2enrZYlsUwzGazTYyj0WgikaCoTCQSDY9EwiRJlphSuVzyeMZ8ktHCbuPYbgzH8WQymUqlCCJBEEQ8vpsgEmQm7Xa7R/H477m9vR2JhCPR8NbLrecvnoWebgZDG8HNjVAoGAoFURRdWloaO7bdbv8FJZN79KTsV8EAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3c9a390cc8932f8d36bb3228d9dc6885/e4706/Android_Wizard_New_IP_Cam_d02.avif 230w", "/en/static/3c9a390cc8932f8d36bb3228d9dc6885/d1af7/Android_Wizard_New_IP_Cam_d02.avif 460w", "/en/static/3c9a390cc8932f8d36bb3228d9dc6885/7f308/Android_Wizard_New_IP_Cam_d02.avif 920w", "/en/static/3c9a390cc8932f8d36bb3228d9dc6885/e1c99/Android_Wizard_New_IP_Cam_d02.avif 1380w", "/en/static/3c9a390cc8932f8d36bb3228d9dc6885/6e1e4/Android_Wizard_New_IP_Cam_d02.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3c9a390cc8932f8d36bb3228d9dc6885/a0b58/Android_Wizard_New_IP_Cam_d02.webp 230w", "/en/static/3c9a390cc8932f8d36bb3228d9dc6885/bc10c/Android_Wizard_New_IP_Cam_d02.webp 460w", "/en/static/3c9a390cc8932f8d36bb3228d9dc6885/966d8/Android_Wizard_New_IP_Cam_d02.webp 920w", "/en/static/3c9a390cc8932f8d36bb3228d9dc6885/445df/Android_Wizard_New_IP_Cam_d02.webp 1380w", "/en/static/3c9a390cc8932f8d36bb3228d9dc6885/87a8c/Android_Wizard_New_IP_Cam_d02.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3c9a390cc8932f8d36bb3228d9dc6885/81c8e/Android_Wizard_New_IP_Cam_d02.png 230w", "/en/static/3c9a390cc8932f8d36bb3228d9dc6885/08a84/Android_Wizard_New_IP_Cam_d02.png 460w", "/en/static/3c9a390cc8932f8d36bb3228d9dc6885/c0255/Android_Wizard_New_IP_Cam_d02.png 920w", "/en/static/3c9a390cc8932f8d36bb3228d9dc6885/b1001/Android_Wizard_New_IP_Cam_d02.png 1380w", "/en/static/3c9a390cc8932f8d36bb3228d9dc6885/81315/Android_Wizard_New_IP_Cam_d02.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3c9a390cc8932f8d36bb3228d9dc6885/c0255/Android_Wizard_New_IP_Cam_d02.png",
              "alt": "Android App Installation Wizard",
              "title": "Android App Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`[03]`}</strong>{` Use the app to scan the QR code from your camera or camera package. If your camera does not have a QR code, please manually select the camera model in the next step.`}</li>
      <li parentName="ul"><strong parentName="li">{`[04]`}</strong>{` Select your camera manufacturer, e.g. `}<strong parentName="li">{`INSTAR`}</strong></li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/422d6e88c755244bdc814ce9ad259f0f/81315/Android_Wizard_New_IP_Cam_d03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACLElEQVQ4y5WSy27TQBSGvWKD26yQKqV1QuK4JpN4PDd75vgynrFNFxFmwQokLkViwzvwBN2w4Wl4I94BimwHBE0qxKdZjObo1/+fM8cJw3C5XJ6enk4mE9d1J5PJdDpdr9cIobOzszAMfd8fqycnJ67rep4XhiFCyFsEThAEvu9ba4UQ8/l8sVjM5/OLgdlsFgTBarUqy1Ip9XhgNpudn597njdlO4dznmVZURRCCITQZmA78PuSpqkQgnPOGKOUcs6H6tbJsqxpmizLkiQhBwghGGN5nltrm6YxxoyXNE3ThDtKKa11NBD/DSFEKbXb7bquAwCEUBRFYyZM2EZUDgAYY9q2zfMcY3xHDwDW2rEqpVS/kFKlUPbOZVmOzdzJTCmVUpZlqbW21qZpyhjjA4xzlqh9bErpmPOOGACePeu6rnt6dRUTMnaDMSYsiaTtxXVdt22bZdlh7D4RwQBQ5HlVVWVRaK2NMYJRzmgvNsbggfgAHJMtz2SuQdega5lrVVS5tpEoIsKccSQAkCTJET2hkcgvyxfL9sOquQ7aa79571evtqnFVPTOVVVprZVSx8wpRf6jj18f3NxefP4efPnh3tw+/PQt4kDwpnfWWo/LFB8F43Xx/LJ5F9Svg/rNZf02NC8xYXGMe3Fd11VVAcDhwPfu2ycU+RSt9mcT9NOIY0dKqbUe1/PozMbOMWF/nvF573x0Pf/JfmDGGAC41/k+8fhVSZJwzsl/8hNVgttK0D55HwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/422d6e88c755244bdc814ce9ad259f0f/e4706/Android_Wizard_New_IP_Cam_d03.avif 230w", "/en/static/422d6e88c755244bdc814ce9ad259f0f/d1af7/Android_Wizard_New_IP_Cam_d03.avif 460w", "/en/static/422d6e88c755244bdc814ce9ad259f0f/7f308/Android_Wizard_New_IP_Cam_d03.avif 920w", "/en/static/422d6e88c755244bdc814ce9ad259f0f/e1c99/Android_Wizard_New_IP_Cam_d03.avif 1380w", "/en/static/422d6e88c755244bdc814ce9ad259f0f/6e1e4/Android_Wizard_New_IP_Cam_d03.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/422d6e88c755244bdc814ce9ad259f0f/a0b58/Android_Wizard_New_IP_Cam_d03.webp 230w", "/en/static/422d6e88c755244bdc814ce9ad259f0f/bc10c/Android_Wizard_New_IP_Cam_d03.webp 460w", "/en/static/422d6e88c755244bdc814ce9ad259f0f/966d8/Android_Wizard_New_IP_Cam_d03.webp 920w", "/en/static/422d6e88c755244bdc814ce9ad259f0f/445df/Android_Wizard_New_IP_Cam_d03.webp 1380w", "/en/static/422d6e88c755244bdc814ce9ad259f0f/87a8c/Android_Wizard_New_IP_Cam_d03.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/422d6e88c755244bdc814ce9ad259f0f/81c8e/Android_Wizard_New_IP_Cam_d03.png 230w", "/en/static/422d6e88c755244bdc814ce9ad259f0f/08a84/Android_Wizard_New_IP_Cam_d03.png 460w", "/en/static/422d6e88c755244bdc814ce9ad259f0f/c0255/Android_Wizard_New_IP_Cam_d03.png 920w", "/en/static/422d6e88c755244bdc814ce9ad259f0f/b1001/Android_Wizard_New_IP_Cam_d03.png 1380w", "/en/static/422d6e88c755244bdc814ce9ad259f0f/81315/Android_Wizard_New_IP_Cam_d03.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/422d6e88c755244bdc814ce9ad259f0f/c0255/Android_Wizard_New_IP_Cam_d03.png",
              "alt": "Android App Installation Wizard",
              "title": "Android App Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`[05]`}</strong>{` Select your camera model, e.g. `}<strong parentName="li">{`IN-8015FullHD`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`[06]`}</strong>{` Once your camera is connected click on `}<strong parentName="li">{`Search`}</strong>{` and the app will detect all INSTAR cameras on your local network. If your camera does not show choose to `}<a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPad/DDNS/"
        }}>{`add it manually`}</a>{`.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8733176b4aed5c358869ce8d613409d0/81315/Android_Wizard_New_IP_Cam_d04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACqUlEQVQ4y42RS2sTURTHZyk0CUWKtk0nkyYzzbyS+77zuPPIzGRqaCRJpaAgWrsRwW4UpVQRP4ArNy4UBLvyG9SV+Amka0VXgl9DZtKWilr8ceEezrn/c/73XknX9VarVSupVCrz8/P1et0qsBdXFF03NFWtVqu1Wq1arVYqlUaj0el0LMtqtNYkXdc1TRsMBgghRVFarVaz2WzIsryq1vGVtY6haWoURZzzWVVRFLmgUacjybZt0zRt26aUIoQIIZRS6xizMFCWCCGzKsaYUgoAsExDcl03CALf97MsS9M0z/PBYOD7vhDCcRzOuRDiNJ/n+SwOw9B1XUkIEcdxGIZxHE8mk+l0miRJHMdpms5ajMfj0Wg0Ltnc3Nza2ppOp5PJJM9ziTHmeR5jDADAGCOEAADsEzjnRd9+3/N9hDCljFKGC/Ok2+1KcRwPh0PHcSCEoASWzJr6vr+xsTEcDrM0Bb3e7ECv10MIBUEgRVGUZRnnnJ6BEOI4juu6nPMsy5IkjfsJYRxTThmnjDHOhBCFbdd1Pc+Df1DaQMDULt8+uPDk5+KzbyvPv8/t/7j48HOP+qBrF5PX19eFEKeGfwMhDOz2eH9p+2B1+3X7zpulW2/lGy8B4QgCyXGcfr8fBAE5AWPMGRvkeZwkZUeEbR1bbWSp0FSxpWJLg7CYVPxzFEVnbaMCTGyDmB0IUZnCABGACMTkOCiRfN+Poigs9L5bPK+gjDOCelevm9d2YNc+1v8NyfO8MAqT0EsFCz3WF45LuhG24d2n+oMX0DIg+re4/LjupZ33c4++Lu8dLe8dVR5/Wbh3CI01pGvnKAtxeUUo33y1sPtJ3j2Udz8s3P9Y334HMYaEwnORZhu2VGIoSG8io0kMBZtt+B9I5dsiiAnE9Mwi6D/4BQvK5ZoDf0JvAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8733176b4aed5c358869ce8d613409d0/e4706/Android_Wizard_New_IP_Cam_d04.avif 230w", "/en/static/8733176b4aed5c358869ce8d613409d0/d1af7/Android_Wizard_New_IP_Cam_d04.avif 460w", "/en/static/8733176b4aed5c358869ce8d613409d0/7f308/Android_Wizard_New_IP_Cam_d04.avif 920w", "/en/static/8733176b4aed5c358869ce8d613409d0/e1c99/Android_Wizard_New_IP_Cam_d04.avif 1380w", "/en/static/8733176b4aed5c358869ce8d613409d0/6e1e4/Android_Wizard_New_IP_Cam_d04.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8733176b4aed5c358869ce8d613409d0/a0b58/Android_Wizard_New_IP_Cam_d04.webp 230w", "/en/static/8733176b4aed5c358869ce8d613409d0/bc10c/Android_Wizard_New_IP_Cam_d04.webp 460w", "/en/static/8733176b4aed5c358869ce8d613409d0/966d8/Android_Wizard_New_IP_Cam_d04.webp 920w", "/en/static/8733176b4aed5c358869ce8d613409d0/445df/Android_Wizard_New_IP_Cam_d04.webp 1380w", "/en/static/8733176b4aed5c358869ce8d613409d0/87a8c/Android_Wizard_New_IP_Cam_d04.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8733176b4aed5c358869ce8d613409d0/81c8e/Android_Wizard_New_IP_Cam_d04.png 230w", "/en/static/8733176b4aed5c358869ce8d613409d0/08a84/Android_Wizard_New_IP_Cam_d04.png 460w", "/en/static/8733176b4aed5c358869ce8d613409d0/c0255/Android_Wizard_New_IP_Cam_d04.png 920w", "/en/static/8733176b4aed5c358869ce8d613409d0/b1001/Android_Wizard_New_IP_Cam_d04.png 1380w", "/en/static/8733176b4aed5c358869ce8d613409d0/81315/Android_Wizard_New_IP_Cam_d04.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8733176b4aed5c358869ce8d613409d0/c0255/Android_Wizard_New_IP_Cam_d04.png",
              "alt": "Android App Installation Wizard",
              "title": "Android App Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`[07]`}</strong>{` You will now be asked to enter the admin login of your camera. If you haven't set it up before this will be the default usermane `}<strong parentName="li">{`admin`}</strong>{` and password `}<strong parentName="li">{`instar`}</strong>{`. This can be changed later inside the app.`}</li>
      <li parentName="ul"><strong parentName="li">{`[08]`}</strong>{` Please choose a name for your camera.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      